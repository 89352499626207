<template>
  <teleport to="#modals">
    <vue-resizable
      v-slot="{ style }"
      class="z-[100]"
      drag-selector=".cursor-grab"
      v-bind="activeModalLocalStorageOptions"
      :min-width="activeModalWidthAndHeight.minWidth"
      :min-height="activeModalWidthAndHeight.minHeight"
      :max-width="activeModalWidthAndHeight?.maxWidth"
      :max-height="activeModalWidthAndHeight?.maxHeight"
      @mount="updatePosition"
      @resize:end="updatePosition"
      @drag:end="updatePosition"
    >
      <div
        ref="resizableElement"
        :style="style"
        class="bg-white rounded-md shadow-lg"
        :class="[isMinimized ? 'max-w-55' : 'h-full', modalClass]"
        data-cy="communication-dialog"
      >
        <div
          class="bg-grey-navigation text-white p-4 rounded-t-md flex justify-between space-x-2"
          :class="{ 'rounded-b-md max-w-55': isMinimized }"
          data-cy="communication-dialog-header"
        >
          <div
            class="flex flex-grow items-center space-x-4 min-w-0"
            data-cy="handle"
          >
            <icon-base
              :icon="IconCardView"
              width="16"
              height="16"
              view-box="0 0 24 24"
              class="transform rotate-90 cursor-grab handle min-w-4"
            />
            <span
              class="font-semibold grow text-white cursor-grab text-ellipsis whitespace-nowrap overflow-hidden"
              @dblclick="toggleMinimize"
            >
              {{ modalTypeMap[modalType].name
              }}{{ dealName ? ` - ${dealName}` : "" }}
            </span>
          </div>
          <div class="flex space-x-2">
            <icon-base
              v-if="isMinimized"
              :icon="IconWidget"
              view-box="0 0 16 14"
              icon-name="Restore"
              :title="$t('COMMON.RESTORE')"
              class="text-white cursor-pointer"
              data-cy="maximize-communication-dialog"
              @click="toggleMinimize"
            />
            <icon-base
              v-else
              :icon="IconSubtract"
              view-box="0 -5 20 20"
              icon-name="Minimize"
              :title="$t('COMMON.MINIMIZE')"
              class="text-white cursor-pointer"
              data-cy="minimize-communication-dialog"
              @click="toggleMinimize"
            />
            <icon-base
              icon="x"
              icon-name="Close"
              class="cursor-pointer text-white"
              @click="emit('close-dialog')"
            />
          </div>
        </div>
        <loader :is-loading="loading" />
        <upload-call-recording
          v-if="
            uploadCallRecordingOptions.enabled &&
            uploadCallRecordingOptions.note
          "
          :call-log="uploadCallRecordingOptions.note"
          @call-note:uploaded="finishCallNoteUploaded"
        />
        <slot name="email-form">
          <!-- to prevent modal data from not being populated-->
          <component
            :is="modalTypeMap[modalType].component"
            v-if="!loading && !uploadCallRecordingOptions.enabled"
            v-show="!isMinimized"
            :class="modalTypeMap[modalType].componentClass"
            is-modal
            @close-dialog="emit('close-dialog')"
            @call-note:create="handleCallNoteCreate"
          />
        </slot>
      </div>
    </vue-resizable>
  </teleport>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import type { DynamicFieldOptions } from "@/models/options";
import { useStore } from "vuex";
import { useLocalStorageSetting } from "@/hooks/options";
import useEmailsStore from "@/stores/emails";
import IconSubtract from "@/components/icons/IconSubtract.vue";
import IconWidget from "@/components/icons/IconWidget.vue";
import IconCardView from "@/components/icons/IconCardView.vue";
import EmailForm from "@/components/emailSending/EmailForm.vue";
import SmsForm from "@/components/communicationLogs/communication/sms/SmsForm.vue";
import TaskForm from "@/components/communicationLogs/forms/CreateTaskForm.vue";
import CreateCallNoteForm from "@/components/communicationLogs/forms/CreateCallNoteForm.vue";
import UploadCallRecording from "@/components/communicationLogs/forms/UploadCallRecording.vue";
import VueResizable from "vue-resizable";
import type { ResizableEventValues } from "@/models/common";
import isEmpty from "lodash/isEmpty";
import {
  DEFAULT_EMAIL_MODAL_OPTIONS,
  DEFAULT_SMS_MODAL_OPTIONS,
  DEFAULT_TASK_MODAL_OPTIONS,
  DEFAULT_CALL_MODAL_OPTIONS
} from "@/helpers/constants/deals";
import { convertToArray } from "@/helpers/common";
import { usePromiseWrapper } from "@/hooks/common";
import { useRoute } from "vue-router";
import type {
  CommunicationModalType,
  CallLog
} from "@/models/communicationLogs";
import { useI18n } from "vue-i18n";
import useSmsStore from "@/stores/sms";
import { CommunicationType } from "@/enums/communicationLogs";

type CallRecordingOptions = {
  enabled: boolean;
  note: CallLog | null;
};

const TOPBAR_HEIGHT = 53;

const emit = defineEmits<{
  "close-dialog": [];
}>();

const props = withDefaults(
  defineProps<{
    initialValues: { x: number; y: number };
    dealId: string | string[];
    anchorBottomLeft?: boolean;
    dealName?: string | undefined;
    type?: CommunicationModalType;
  }>(),
  {
    initialValues: () => ({ x: 0, y: 0 }),
    dealId: "",
    anchorBottomLeft: false,
    dealName: "",
    type: CommunicationType.email
  }
);

const { getters, dispatch } = useStore();
const emailStore = useEmailsStore();
const smsStore = useSmsStore();
const route = useRoute();
const { t } = useI18n();

const emailOptions = useLocalStorageSetting(
  "emailModalOptions",
  DEFAULT_EMAIL_MODAL_OPTIONS
);

const smsOptions = useLocalStorageSetting(
  "smsModalOptions",
  DEFAULT_SMS_MODAL_OPTIONS
);

const taskOptions = useLocalStorageSetting(
  "taskModalOptions",
  DEFAULT_TASK_MODAL_OPTIONS
);

const callOptions = useLocalStorageSetting(
  "callModalOptions",
  DEFAULT_CALL_MODAL_OPTIONS
);

const uploadCallRecordingOptions = ref<CallRecordingOptions>({
  enabled: false,
  note: null
});

const emailWidthAndHeight = ref({
  width: DEFAULT_EMAIL_MODAL_OPTIONS.width,
  height: DEFAULT_EMAIL_MODAL_OPTIONS.height,
  minWidth: 500,
  maxWidth: undefined,
  minHeight: 400,
  maxHeight: undefined,
  minimizedWidth: 220,
  minimizedHeight: TOPBAR_HEIGHT
});

const smsWidthAndHeight = ref({
  width: DEFAULT_SMS_MODAL_OPTIONS.width,
  height: DEFAULT_SMS_MODAL_OPTIONS.height,
  minWidth: 550,
  maxWidth: undefined,
  minHeight: 400,
  maxHeight: undefined,
  minimizedWidth: 220,
  minimizedHeight: TOPBAR_HEIGHT
});

const taskWidthAndHeight = ref({
  width: DEFAULT_TASK_MODAL_OPTIONS.width,
  height: DEFAULT_TASK_MODAL_OPTIONS.height,
  minWidth: 260,
  maxWidth: 400,
  minHeight: 410,
  maxHeight: 410,
  minimizedWidth: 220,
  minimizedHeight: TOPBAR_HEIGHT
});

const callWidthAndHeight = ref({
  width: 500,
  height: 300,
  minWidth: 500,
  maxWidth: undefined,
  minHeight: 300,
  maxHeight: undefined,
  minimizedWidth: 220,
  minimizedHeight: TOPBAR_HEIGHT
});

const modalTypeMap = {
  [CommunicationType.email]: {
    name: t("COMMON.EMAIL"),
    store: emailStore,
    options: emailOptions,
    widthAndHeight: emailWidthAndHeight,
    component: EmailForm,
    componentClass: undefined
  },
  [CommunicationType.sms]: {
    name: t("COMMON.SMS"),
    store: smsStore,
    options: smsOptions,
    widthAndHeight: smsWidthAndHeight,
    component: SmsForm,
    componentClass: undefined
  },
  [CommunicationType.task]: {
    name: t("COMMON.TASK"),
    store: undefined,
    options: taskOptions,
    widthAndHeight: taskWidthAndHeight,
    component: TaskForm,
    componentClass: undefined
  },
  [CommunicationType.call]: {
    name: t("COMMON.CALL"),
    store: undefined,
    options: callOptions,
    widthAndHeight: callWidthAndHeight,
    component: CreateCallNoteForm,
    componentClass: `h-[calc(100%-${TOPBAR_HEIGHT}px)]`
  }
};

const isMinimized = ref(false);
const resizableElement = ref<HTMLElement | null>(null);

const dynamicFieldsOptions = computed<DynamicFieldOptions | null>(
  () => getters["options/dynamicFields"]
);

const isEmailModal = computed(() => props.type === CommunicationType.email);
const modalType = computed(() => props.type);

const activeModalWidthAndHeight = computed(
  () => modalTypeMap[modalType.value].widthAndHeight.value
);

const activeModalLocalStorageOptions = computed(
  () => modalTypeMap[modalType.value].options.value
);

const modalClass = computed(() => (route.params.id ? "deal-modal" : ""));

const initPosition = (position: { x: number; y: number }) => {
  const { width, height } = activeModalWidthAndHeight.value;
  const left = props.anchorBottomLeft ? position.x + width : position.x;
  const top = props.anchorBottomLeft ? position.y - height : position.y;
  updatePosition(
    {
      left,
      top,
      width,
      height
    },
    true
  );
};

const updatePosition = (
  positionAndEvent: ResizableEventValues,
  init = false
) => {
  const initOffset = init ? resizableElement.value?.clientWidth || 0 : 0;
  const { left, top, width, height } = positionAndEvent;
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  const adjustedLeft = left - initOffset;
  const adjustedTop = top < 0 ? 0 : top;

  // Ensure modal is within viewport
  const finalLeft = Math.min(Math.max(adjustedLeft, 0), viewportWidth - width);
  const finalTop = Math.min(Math.max(adjustedTop, 0), viewportHeight - height);

  const { left: currentLeft, top: currentTop } =
    activeModalLocalStorageOptions.value;

  const updatedOptions = {
    // Ensure every update triggers a check for a modal position
    left: currentLeft === finalLeft ? finalLeft - 1 : finalLeft,
    top: currentTop === finalTop ? finalTop - 1 : finalTop,
    width,
    height
  };

  modalTypeMap[modalType.value].options.value = {
    ...modalTypeMap[modalType.value].options.value,
    ...updatedOptions
  };
};

const toggleMinimize = () => {
  if (!isMinimized.value) {
    const { width, height } = activeModalLocalStorageOptions.value;
    activeModalLocalStorageOptions.value.restoreHeight = height;
    activeModalLocalStorageOptions.value.restoreWidth = width;
  }
  isMinimized.value = !isMinimized.value;

  const { width, height, minimizedWidth, minimizedHeight } =
    activeModalWidthAndHeight.value;

  const { restoreWidth, restoreHeight, left, top } =
    activeModalLocalStorageOptions.value;

  const setWidth = isMinimized.value ? minimizedWidth : restoreWidth || width;
  const setHeight = isMinimized.value
    ? minimizedHeight
    : restoreHeight || height;

  updatePosition({
    left,
    top,
    width: setWidth,
    height: setHeight
  });
};

const { fetchWrapper: getCommunicationInfo, loading } = usePromiseWrapper(
  async () => {
    if (
      [CommunicationType.email, CommunicationType.sms].includes(modalType.value)
    ) {
      if (isEmpty(dynamicFieldsOptions.value)) {
        await dispatch("options/getDynamicFields");
      }
      const action = isEmailModal.value
        ? emailStore.getApplicationEmailModalData
        : smsStore.getApplicationSmsModalData;
      await action(convertToArray(props.dealId));
    }

    const { left, top } = modalTypeMap[modalType.value].options.value;
    if (!left && !top) {
      initPosition(props.initialValues);
    }
  }
);

const handleCallNoteCreate = (upload: boolean, note: CallLog) => {
  if (upload) {
    uploadCallRecordingOptions.value.enabled = true;
    uploadCallRecordingOptions.value.note = note;
    return;
  }
  emit("close-dialog");
};

const finishCallNoteUploaded = () => {
  uploadCallRecordingOptions.value.enabled = false;
  uploadCallRecordingOptions.value.note = null;
  emit("close-dialog");
};

onMounted(getCommunicationInfo);
</script>
